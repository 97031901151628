<div class="flex-container" *ngIf="uiConfiguration.showFileNumber">
  <span class="flex-item-3">File Number:</span>
  <span class="flex-item-4" data-testid="discharge-details-file-number-text">{{ order.lien?.fileNumber }}</span>
</div>
<div class="flex-container">
  <span class="flex-item-3">Discharge Number:</span>
  <span class="flex-item-4" data-testid="discharge-details-number-text">{{ order.discharge?.dischargeNumber }}</span>
</div>
<div class="flex-container">
  <span class="flex-item-3">Discharge Date:</span>
  <span class="flex-item-4" data-testid="discharge-details-date-text">{{ order.discharge?.dischargeDate | localizedDate: "yyyy-MM-dd" }}</span>
</div>
<div class="flex-container" *ngIf="uiConfiguration.showDischargeType && order.discharge?.dischargeType">
  <span class="flex-item-3">Discharge Type:</span>
  <span class="flex-item-4" data-testid="discharge-details-type-text">{{ getDischargeType() }}</span>
</div>
<div class="flex-container" *ngIf="uiConfiguration.showFormType">
  <span class="flex-item-3">QC Form Type:</span>
  <span class="flex-item-4" data-testid="lien-details-authority-text">{{ order.lien?.qcFormTypeID }}</span>
</div>
<div class="flex-container" *ngIf="uiConfiguration.showAuthorityText">
  <span class="flex-item-3">Authority Text:</span>
  <span class="flex-item-4" data-testid="lien-details-authority-text">{{ order.lien?.authorityText }}</span>
</div>
<div class="flex-container" *ngIf="uiConfiguration.showSumOfHypothec && order.lien?.sumOfHypothec">
  <span class="flex-item-3">Sum of Hypothec:</span>
  <span class="flex-item-4" data-testid="lien-details-authority-text">{{ order.lien?.sumOfHypothec }}</span>
</div>
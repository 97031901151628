import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ValidationProblem } from '../../interfaces/validation/validation-problem';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { ePartyType } from '../../enums';
import { AddressFormComponent } from '../address-form/address-form.component';
import { Order } from '../../interfaces/orders/order';
import { PartyUIConfiguration } from '../../interfaces/ui-configurations/party-ui-configuration';
import { Party } from '../../interfaces/parties/party';
import { EmptyEntityService } from '../../services/empty-entity-service/empty-entity.service';
import { SelectOption } from '../../interfaces/select-option';
import { EnumeratedTypesRepositoryService } from '../../services/enumerated-types/enumerated-types-repository.service';
import { DateUtils } from '../../date-utils';

@Component({
  selector: 'lib-parties-form',
  templateUrl: './parties-form.component.html',
  styleUrls: ['./parties-form.component.css']
})
export class PartiesFormComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(AddressFormComponent, { static: false }) addressFormComponent!: AddressFormComponent;

  @Input() businessPartyType!: ePartyType;
  @Input() individualPartyType!: ePartyType;
  @Input() defaultPartyType = this.businessPartyType;
  @Input() partyTypeTitle: string = 'Party';
  
  @Input() order!: Order;
  @Input() uiConfiguration!: PartyUIConfiguration;
  // Debtor to bind to fields and post to RegHub when pushing add button.
  @Input() party: Party = this.emptyEntityService.getEmptyParty(this.defaultPartyType, this.order?.id ?? '');
  @Input() errors$!: Observable<ValidationProblem[] | undefined>;
  @Input() showSaveButton = true;
  @Input() showClearButton = true;
  @Input() showDeleteButton = true;

  @Output() partySavedEvent = new EventEmitter<Party>();
  @Output() partyRemovedEvent = new EventEmitter<Party>();
  @Output() partyFormValueChangedEvent = new EventEmitter<Party>();

  protected displayBusinessFields: boolean = false;
  protected displayIndividualFields: boolean = false;

  private onDestroy$ = new Subject<void>();

  protected partyDetails!: FormGroup;
  protected contactDetails!: FormGroup;

  firstNameError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  middleNameError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  lastNameError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  busNameError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  dateOfBirthError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  emailError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  phoneNumberError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  faxNumberError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);
  generationError$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(null);

  generations: SelectOption[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private emptyEntityService: EmptyEntityService,
    private enumeratedTypesService: EnumeratedTypesRepositoryService,
    private cdr: ChangeDetectorRef) { }

  private initForms() {
    this.partyDetails = this.formBuilder.group({
      id: [this.party.id],
      partyTypeID: [this.party.partyTypeID],
      firstName: [this.party.firstName],
      middleName: [this.party.middleName],
      lastName: [this.party.lastName],
      busName: [this.party.busName],
      priority: [this.party.priority]
    });

    if(this.uiConfiguration.contactDetailsConfiguration) {
      this.contactDetails = this.formBuilder.group({})

      this.contactDetails.addControl('phoneNumber', this.formBuilder.control(this.party.contactDetails?.phoneNumber));
      this.contactDetails.addControl('faxNumber', this.formBuilder.control(this.party.contactDetails?.faxNumber));
      this.contactDetails.addControl('email', this.formBuilder.control(this.party.contactDetails?.email));

      this.partyDetails.addControl('contactDetails', this.contactDetails);
    }

    if (this.uiConfiguration?.showEstate) {
      this.partyDetails.addControl('isEstate', this.formBuilder.control(this.party.isEstate ?? false));
    }

    if(this.uiConfiguration?.showGeneration) {
      this.partyDetails.addControl('generationID', this.formBuilder.control(this.party.generationID));

      this.enumeratedTypesService.getGenerations().subscribe(generations => {
        this.generations = generations;
        this.partyDetails.get('generationID')?.setValue(this.party.generationID, { emitEvent: false });
      });
    }

    this.partyDetails.addControl('dateOfBirth', this.formBuilder.control(this.party.dateOfBirth));

    this.displayBusinessFields = this.partyDetails.get('partyTypeID')?.value == this.businessPartyType;
    this.displayIndividualFields = this.partyDetails.get('partyTypeID')?.value === this.individualPartyType;
  }

  private initAddressForms() {
    if (this.addressFormComponent?.addressDetails) {
      this.contactDetails.setControl('address', this.addressFormComponent.addressDetails);
    }
  }

  private initSubscriptions() {
    this.partyDetails.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.partyFormValueChangedEvent.emit(this.getParty()));

    if(this.errors$) {
      this.errors$
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(errors => this.pushErrors(errors));
    }

    this.partyDetails.get('partyTypeID')!.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(val => {
        this.displayIndividualFields = val === this.individualPartyType;
        this.displayBusinessFields = val === this.businessPartyType;

        this.cdr.detectChanges();
        this.initAddressForms();
      });
  }

  ngOnInit(): void {
    this.initForms()
  }

  ngAfterViewInit(): void {
    this.initAddressForms();
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  clearFields() {
    var partyId = this.party.id;
    this.party = this.emptyEntityService.getEmptyParty(this.businessPartyType, this.order?.id ?? '');
    this.party.id = partyId;
    this.displayIndividualFields = this.party.partyTypeID === this.individualPartyType;
    this.displayBusinessFields = this.party.partyTypeID === this.businessPartyType;

    if(this.party.contactDetails) {
      this.party.contactDetails.address.countryCode = "CA";
    }

    this.partyDetails.reset(this.party);
    this.partyDetails.setErrors(null);
    this.partyDetails.markAsPristine();
    this.partyDetails.markAsUntouched();
    this.partyDetails.updateValueAndValidity();
  }

  public saveParty() {
    const party = this.getParty();
    this.partySavedEvent.emit(party);
    this.clearFields();
  }

  public removeParty() {
    const party = this.getParty();
    this.ensurePartyNames(party);
    this.partyRemovedEvent.emit(party);
    this.clearFields();
  }

  pushErrors(errors: ValidationProblem[] | undefined) {
    if (!errors) {
      return;
    }

    this.addressFormComponent?.pushErrors(errors);

    this.firstNameError$.next(errors?.filter(error => error.path.includes('/name') || error.path.includes('/firstname')).at(0)?.userFriendlyMessage);
    this.middleNameError$.next(errors?.filter(error => error.path.includes('/middlename')).at(0)?.userFriendlyMessage);
    this.lastNameError$.next(errors?.filter(error => error.path.includes('/name') || error.path.includes('/lastname')).at(0)?.userFriendlyMessage);
    this.busNameError$.next(errors?.filter(error => error.path.includes('/name') || error.path.includes('/busname')).at(0)?.userFriendlyMessage);
    this.dateOfBirthError$.next(errors?.filter(error => error.path.includes('/dateofbirth')).at(0)?.userFriendlyMessage);
    this.emailError$.next(errors?.filter(error => error.path.includes('/email')).at(0)?.userFriendlyMessage);
    this.phoneNumberError$.next(errors?.filter(error => error.path.includes('/phonenumber')).at(0)?.userFriendlyMessage);
    this.faxNumberError$.next(errors?.filter(error => error.path.includes('/faxnumber')).at(0)?.userFriendlyMessage);
  }

  setParty(party: Party) {
    this.party = party;

    this.initForms()

    if(this.addressFormComponent) {
      this.addressFormComponent.address = this.party.contactDetails?.address;

      this.addressFormComponent.initForm();
      this.addressFormComponent.initJurisdictionsForm();
    }

    this.initAddressForms();
    this.initSubscriptions();
  }

  public getParty() {
    var partyCopy = JSON.parse(JSON.stringify(this.partyDetails.getRawValue()));
    this.ensurePartyNames(partyCopy);
    this.ensureAddress(partyCopy);

    const dateOfBirth = this.partyDetails.get('dateOfBirth')?.value;
    
    if(this.uiConfiguration.showDateOfBirth &&
      this.displayIndividualFields &&
      dateOfBirth) {
        partyCopy.dateofbirth = DateUtils.getDateNoTimestamp(dateOfBirth);
    }

    return partyCopy;
  }

  public anyAddressControlHasValueBesidesCountryCode(partyForm: FormGroup): boolean {
    const addressForm = partyForm.get('contactDetails')?.get('address') as any;

    if (!addressForm) {
      return false;
    }

    return Object.keys(addressForm.controls).some(key => {
      if (key === 'countryCode') {
        return false;
      }

      return addressForm.get(key)?.value;
    });
  }

  private ensurePartyNames(party: Party) {
    if (this.displayBusinessFields) {
      // if business party, don't save individual fields
      party.firstName = null;
      party.middleName = null;
      party.lastName = null;
      party.dateOfBirth = null;
    } else {
      // if individual party, don't save business fields
      party.busName = null;
    }
  }

  private ensureAddress(party: Party) {
    if(!party.contactDetails?.address) {
      return;
    }

    if((this.displayBusinessFields && !this.uiConfiguration.contactDetailsConfiguration.showBusinessAddress) ||
      (this.displayIndividualFields && !this.uiConfiguration.contactDetailsConfiguration.showIndividualAddress)) {
        (party.contactDetails.address as any) = undefined;
      }
  }
}
